import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide48/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide48/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide48/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide48/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide48/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      5 pasos para crear un mapa de procesos de un Call Center
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Cuando los agentes del centro de llamadas están al teléfono, enfrentan una
      gran presión. Tienen a alguien al otro lado de la línea que espera que
      tengan todas las respuestas. Y eso genera mucha presión en ellos.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    La clave está en proporcionar herramientas claras y efectivas para que los
    agentes del centro de llamadas puedan desempeñarse mejor en su trabajo.
    <br />
    <br />
    Al seguir las mejores prácticas para crear diagramas de flujo, garantizamos
    que los procedimientos estén documentados de manera clara y concisa, lo que
    facilita su comprensión y aplicación por parte de los agentes en sus
    interacciones con los clientes.
  </p>
)

const data = {
  start: {
    support: "Guía | Call Center",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Identificar formas de comunicar.",
      },
      {
        key: 2,
        text: "Creación de diagramas de flujo transversales.",
      },
      {
        key: 3,
        text: "Cómo crear una narrativa.",
      },
      {
        key: 4,
        text: "Revisión y pruebas de procesos.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
